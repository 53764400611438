import { DateObject } from '../common/dateobject';
import { approximateStringMatching } from './utils';
import { KeyCode } from '../common/keycode';
import { Key } from '../common/key';
import { extend, isPresent, isDocumentAvailable, millisecondDigitsInFormat, millisecondStepFor, isValidDate } from '../common/utils';
import { Observable } from '../common/observable';
import { DateInputInteractionMode } from './interaction-mode';
import { isEqual, cloneDate } from '@progress/kendo-date-math';
import { Constants } from '../common/constants';
const DEFAULT_SEGMENT_STEP = 1;
const DRAG_START = "dragStart";
const DROP = "drop";
const TOUCH_START = "touchstart";
const MOUSE_DOWN = "mousedown";
const MOUSE_UP = "mouseup";
const CLICK = "click";
const INPUT = "input";
const KEY_DOWN = "keydown";
const FOCUS = "focus";
const BLUR = "blur";
const PASTE = "paste";
const MOUSE_SCROLL = "DOMMouseScroll";
const MOUSE_WHEEL = "mousewheel";
const VALUE_CHANGE = "valueChange";
const INPUT_END = "inputEnd";
const BLUR_END = "blurEnd";
const FOCUS_END = "focusEnd";
const CHANGE = "change";
const defaultDateInputOptions = {
    format: "d",
    hasPlaceholder: false,
    placeholder: null,
    cycleTime: true,
    locale: null,
    steps: {
        millisecond: DEFAULT_SEGMENT_STEP,
        second: DEFAULT_SEGMENT_STEP,
        minute: DEFAULT_SEGMENT_STEP,
        hour: DEFAULT_SEGMENT_STEP,
        day: DEFAULT_SEGMENT_STEP,
        month: DEFAULT_SEGMENT_STEP,
        year: DEFAULT_SEGMENT_STEP
    },
    formatPlaceholder: null,
    events: {
        [VALUE_CHANGE]: null,
        [INPUT]: null,
        [INPUT_END]: null,
        [FOCUS]: null,
        [FOCUS_END]: null,
        [BLUR]: null,
        [BLUR_END]: null,
        [KEY_DOWN]: null,
        [MOUSE_WHEEL]: null,
        [CHANGE]: null
    },
    selectNearestSegmentOnFocus: false,
    selectPreviousSegmentOnBackspace: false,
    enableMouseWheel: false,
    allowCaretMode: false,
    autoSwitchParts: true,
    autoSwitchKeys: [],
    twoDigitYearMax: Constants.twoDigitYearMax,
    autoCorrectParts: true,
    autoFill: false
};
export class DateInput extends Observable {
    constructor(element, options) {
        super(options);
        this.dateObject = null;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.currentText = '';
        this.currentFormat = '';
        this.interactionMode = DateInputInteractionMode.None;
        this.previousElementSelection = { start: 0, end: 0 };
        this.init(element, options);
    }
    get value() {
        return this.dateObject && this.dateObject.getValue();
    }
    init(element, options) {
        let dateValue = isValidDate(this.options.value) ? cloneDate(this.options.value) : new Date(options.formattedValue);
        if (!isValidDate(dateValue)) {
            dateValue = null;
        }
        this.element = element;
        // this.element._kendoWidget = this;
        this.options = extend({}, defaultDateInputOptions, options, { steps: Object.assign({}, defaultDateInputOptions.steps, options.steps) });
        this.intl = this.options.intlService;
        this.dateObject = this.createDateObject();
        this.dateObject.setValue(dateValue);
        this.setTextAndFormat();
        this.bindEvents();
        this.resetSegmentValue = true;
        this.interactionMode = DateInputInteractionMode.None;
        this.forceUpdate();
    }
    destroy() {
        this.unbindEvents();
        this.dateObject = null;
        super.destroy();
    }
    bindEvents() {
        this.onElementDragStart = this.onElementDragStart.bind(this);
        this.element.addEventListener(DRAG_START, this.onElementDragStart);
        this.onElementDrop = this.onElementDrop.bind(this);
        this.element.addEventListener(DROP, this.onElementDrop);
        this.onElementClick = this.onElementClick.bind(this);
        this.element.addEventListener(CLICK, this.onElementClick);
        this.onElementMouseDown = this.onElementMouseDown.bind(this);
        this.element.addEventListener(MOUSE_DOWN, this.onElementMouseDown);
        this.element.addEventListener(TOUCH_START, this.onElementMouseDown);
        this.onElementMouseUp = this.onElementMouseUp.bind(this);
        this.element.addEventListener(MOUSE_UP, this.onElementMouseUp);
        this.onElementInput = this.onElementInput.bind(this);
        this.element.addEventListener(INPUT, this.onElementInput);
        this.onElementKeyDown = this.onElementKeyDown.bind(this);
        this.element.addEventListener(KEY_DOWN, this.onElementKeyDown);
        this.onElementFocus = this.onElementFocus.bind(this);
        this.element.addEventListener(FOCUS, this.onElementFocus);
        this.onElementBlur = this.onElementBlur.bind(this);
        this.element.addEventListener(BLUR, this.onElementBlur);
        this.onElementChange = this.onElementChange.bind(this);
        this.element.addEventListener(CHANGE, this.onElementChange);
        this.onElementPaste = this.onElementPaste.bind(this);
        this.element.addEventListener(PASTE, this.onElementPaste);
        this.onElementMouseWheel = this.onElementMouseWheel.bind(this);
        this.element.addEventListener(MOUSE_SCROLL, this.onElementMouseWheel);
        this.element.addEventListener(MOUSE_WHEEL, this.onElementMouseWheel);
    }
    unbindEvents() {
        this.element.removeEventListener(DRAG_START, this.onElementDragStart);
        this.element.removeEventListener(DROP, this.onElementDrop);
        this.element.removeEventListener(TOUCH_START, this.onElementMouseDown);
        this.element.removeEventListener(MOUSE_DOWN, this.onElementMouseDown);
        this.element.removeEventListener(MOUSE_UP, this.onElementMouseUp);
        this.element.removeEventListener(CLICK, this.onElementClick);
        this.element.removeEventListener(INPUT, this.onElementInput);
        this.element.removeEventListener(KEY_DOWN, this.onElementKeyDown);
        this.element.removeEventListener(FOCUS, this.onElementFocus);
        this.element.removeEventListener(BLUR, this.onElementBlur);
        this.element.removeEventListener(CHANGE, this.onElementChange);
        this.element.removeEventListener(PASTE, this.onElementPaste);
        this.element.removeEventListener(MOUSE_SCROLL, this.onElementMouseWheel);
        this.element.removeEventListener(MOUSE_WHEEL, this.onElementMouseWheel);
    }
    setOptions(options, refresh = false) {
        this.options = extend({}, this.options, options, { steps: Object.assign({}, defaultDateInputOptions.steps, options.steps) });
        this.setDateObjectOptions();
        if (refresh) {
            this.unbindEvents();
            this.init(this.element, this.options);
        }
    }
    /**
     * @hidden
     */
    setDateObjectOptions() {
        if (this.dateObject) {
            const newOptions = this.getDateObjectOptions();
            this.dateObject.setOptions(newOptions);
        }
    }
    /**
     * @hidden
     */
    resetLocale() {
        this.unbindEvents();
        this.init(this.element, this.options);
    }
    /**
     * @hidden
     */
    isInCaretMode() {
        return this.interactionMode === DateInputInteractionMode.Caret;
    }
    focus() {
        this.element.focus();
        if (this.options.selectNearestSegmentOnFocus) {
            this.selectNearestSegment(0);
        }
    }
    /**
     * @hidden
     */
    onElementDragStart(e) {
        e.preventDefault();
    }
    /**
     * @hidden
     */
    onElementDrop(e) {
        e.preventDefault();
    }
    /**
     * @hidden
     */
    onElementMouseDown() {
        this.mouseDownStarted = true;
        this.focusedPriorToMouseDown = this.isActive;
    }
    /**
     * @hidden
     */
    onElementMouseUp(e) {
        this.mouseDownStarted = false;
        e.preventDefault();
    }
    /**
     * @hidden
     */
    onElementClick(e) {
        this.mouseDownStarted = false;
        this.switchedPartOnPreviousKeyAction = false;
        const selection = this.selection;
        if (this.isInCaretMode()) {
            // explicitly refresh the input element value
            // caret mode can change the number of symbols in the element
            // thus clicking on a segment can result in incorrect selection
            this.forceUpdate();
        }
        if (e.detail === 3) {
            // when 3 clicks occur, leave the native event to handle the change
            // this results in selecting the whole element value
        }
        else {
            if (this.isActive && this.options.selectNearestSegmentOnFocus) {
                const selectionPresent = this.element.selectionStart !== this.element.selectionEnd;
                const placeholderToggled = isPresent(this.options.placeholder) &&
                    !this.dateObject.hasValue() &&
                    !this.focusedPriorToMouseDown;
                // focus first segment if the user hasn't selected something during mousedown and if the placeholder was just toggled
                const selectFirstSegment = !selectionPresent && placeholderToggled;
                const index = selectFirstSegment ? 0 : this.caret()[0];
                this.selectNearestSegment(index);
            }
            else {
                this.setSelection(this.selectionByIndex(selection.start));
            }
        }
    }
    /**
     * @hidden
     */
    onElementInput(e) {
        this.triggerInput({ event: e });
        if (!this.element || !this.dateObject) {
            return;
        }
        const switchedPartOnPreviousKeyAction = this.switchedPartOnPreviousKeyAction;
        if (this.isPasteInProgress) {
            if (this.options.allowCaretMode) {
                // pasting should leave the input with caret
                // thus allow direct input instead of selection mode
                this.resetSegmentValue = false;
            }
            this.updateOnPaste(e);
            this.isPasteInProgress = false;
            return;
        }
        const keyDownEvent = this.keyDownEvent || {};
        const isBackspaceKey = keyDownEvent.keyCode === KeyCode.BACKSPACE || keyDownEvent.key === Key.BACKSPACE;
        const isDeleteKey = keyDownEvent.keyCode === KeyCode.DELETE || keyDownEvent.key === Key.DELETE;
        const originalInteractionMode = this.interactionMode;
        if (this.options.allowCaretMode &&
            originalInteractionMode !== DateInputInteractionMode.Caret &&
            !isDeleteKey && !isBackspaceKey) {
            this.resetSegmentValue = true;
        }
        if (this.options.allowCaretMode) {
            this.interactionMode = DateInputInteractionMode.Caret;
        }
        else {
            this.interactionMode = DateInputInteractionMode.Selection;
        }
        const hasCaret = this.isInCaretMode();
        if (hasCaret && this.keyDownEvent.key === Key.SPACE) {
            // do not allow custom "holes" in the date segments
            this.restorePreviousInputEventState();
            return;
        }
        const oldExistingDateValue = this.dateObject && this.dateObject.getValue();
        const oldDateValue = this.dateObject ? this.dateObject.value : null;
        const { text: currentText, format: currentFormat } = this.dateObject.getTextAndFormat();
        this.currentFormat = currentFormat;
        let oldText = "";
        if (hasCaret) {
            if (isBackspaceKey || isDeleteKey) {
                oldText = this.previousElementValue;
            }
            else if (originalInteractionMode === DateInputInteractionMode.Caret) {
                oldText = this.previousElementValue;
            }
            else {
                oldText = currentText;
            }
        }
        else {
            oldText = currentText;
        }
        const newText = this.elementValue;
        let diff = approximateStringMatching({
            oldText: oldText,
            newText: newText,
            formatPattern: this.currentFormat,
            selectionStart: this.selection.start,
            isInCaretMode: hasCaret,
            keyEvent: this.keyDownEvent
        });
        if (diff && diff.length && diff[0] && diff[0][1] !== Constants.formatSeparator) {
            this.switchedPartOnPreviousKeyAction = false;
        }
        if (hasCaret && (!diff || diff.length === 0)) {
            this.restorePreviousInputEventState();
            return;
        }
        else if (hasCaret && diff.length === 1) {
            if (!diff[0] || !diff[0][0]) {
                this.restorePreviousInputEventState();
                return;
            }
            else if (hasCaret && diff[0] &&
                (diff[0][0] === Constants.formatSeparator || diff[0][1] === Constants.formatSeparator)) {
                this.restorePreviousInputEventState();
                return;
            }
        }
        const navigationOnly = (diff.length === 1 && diff[0][1] === Constants.formatSeparator);
        const parsePartsResults = [];
        let switchPart = false;
        let error = null;
        if (!navigationOnly) {
            for (let i = 0; i < diff.length; i++) {
                const parsePartResult = this.dateObject.parsePart({
                    symbol: diff[i][0],
                    currentChar: diff[i][1],
                    resetSegmentValue: this.resetSegmentValue,
                    cycleSegmentValue: !this.isInCaretMode(),
                    rawTextValue: this.element.value,
                    isDeleting: isBackspaceKey || isDeleteKey,
                    originalFormat: this.currentFormat
                });
                parsePartsResults.push(parsePartResult);
                if (!parsePartResult.value) {
                    error = { type: "parse" };
                }
                switchPart = parsePartResult.switchToNext;
            }
        }
        if (!this.options.autoSwitchParts) {
            switchPart = false;
        }
        this.resetSegmentValue = false;
        const hasFixedFormat = this.options.format === this.currentFormat ||
            // all not fixed formats are 1 symbol, e.g. "d"
            (isPresent(this.options.format) && this.options.format.length > 1);
        const lastParseResult = parsePartsResults[parsePartsResults.length - 1];
        const lastParseResultHasNoValue = lastParseResult && !isPresent(lastParseResult.value);
        const parsingFailedOnDelete = (hasCaret && (isBackspaceKey || isDeleteKey) && lastParseResultHasNoValue);
        const resetPart = lastParseResult ? lastParseResult.resetPart : false;
        const newExistingDateValue = this.dateObject.getValue();
        const hasExistingDateValueChanged = !isEqual(oldExistingDateValue, newExistingDateValue);
        const newDateValue = this.dateObject.value;
        let symbolForSelection;
        const currentSelection = this.selection;
        if (hasCaret) {
            const diffChar = diff && diff.length > 0 ? diff[0][0] : null;
            let hasLeadingZero = this.dateObject.getLeadingZero()[diffChar];
            if (diff.length && diff[0][0] !== Constants.formatSeparator) {
                if (switchPart) {
                    this.forceUpdateWithSelection();
                    this.switchDateSegment(1);
                }
                else if (resetPart) {
                    symbolForSelection = this.currentFormat[currentSelection.start];
                    if (symbolForSelection) {
                        this.forceUpdate();
                        this.setSelection(this.selectionBySymbol(symbolForSelection));
                    }
                    else {
                        this.restorePreviousInputEventState();
                    }
                }
                else if (parsingFailedOnDelete) {
                    this.forceUpdate();
                    if (diff.length && diff[0][0] !== Constants.formatSeparator) {
                        this.setSelection(this.selectionBySymbol(diff[0][0]));
                    }
                }
                else if (lastParseResultHasNoValue) {
                    if (e.data === "0" && hasLeadingZero) {
                        // do not reset element value on a leading zero
                        // wait for consecutive input to determine the value
                    }
                    else if (isPresent(oldExistingDateValue) && !isPresent(newExistingDateValue)) {
                        this.restorePreviousInputEventState();
                    }
                    else if (!isPresent(oldExistingDateValue) && isPresent(newExistingDateValue)) {
                        this.forceUpdateWithSelection();
                    }
                    else if (isPresent(oldExistingDateValue) && isPresent(newExistingDateValue)) {
                        if (hasExistingDateValueChanged) {
                            this.forceUpdateWithSelection();
                        }
                        else {
                            this.restorePreviousInputEventState();
                        }
                    }
                    else if (!isPresent(oldExistingDateValue) && !isPresent(newExistingDateValue)) {
                        this.forceUpdateWithSelection();
                    }
                    else if (oldDateValue !== newDateValue) {
                        // this can happen on auto correct when no valid value is parsed
                    }
                    else {
                        this.restorePreviousInputEventState();
                    }
                }
                else if (!lastParseResultHasNoValue) {
                    // the user types a valid but incomplete date (e.g. year "123" with format "yyyy")
                    // let them continue typing, but refresh for not fixed formats
                    if (!hasFixedFormat) {
                        this.forceUpdateWithSelection();
                    }
                }
            }
            else {
                if (!this.options.autoSwitchParts && diff[0][1] === Constants.formatSeparator) {
                    // do not change the selection when a separator is pressed
                    // this should happen only if autoSwitchKeys contains the separator explicitly
                }
                else {
                    this.setSelection(this.selectionBySymbol(diff[0][0]));
                }
            }
        }
        else if (!hasCaret) {
            this.forceUpdate();
            if (diff.length && diff[0][0] !== Constants.formatSeparator) {
                this.setSelection(this.selectionBySymbol(diff[0][0]));
            }
            if (this.options.autoSwitchParts) {
                if (navigationOnly) {
                    this.resetSegmentValue = true;
                    if (!switchedPartOnPreviousKeyAction) {
                        this.switchDateSegment(1);
                    }
                    this.switchedPartOnPreviousKeyAction = true;
                }
                else if (switchPart) {
                    this.switchDateSegment(1);
                    this.switchedPartOnPreviousKeyAction = true;
                }
            }
            else {
                if (lastParseResult && lastParseResult.switchToNext) {
                    // the value is complete and should be switched, but the "autoSwitchParts" option prevents this
                    // ensure that the segment value can be reset on next input
                    this.resetSegmentValue = true;
                }
                else if (navigationOnly) {
                    this.resetSegmentValue = true;
                    if (!switchedPartOnPreviousKeyAction) {
                        this.switchDateSegment(1);
                    }
                    this.switchedPartOnPreviousKeyAction = true;
                }
            }
            if (isBackspaceKey && this.options.selectPreviousSegmentOnBackspace) {
                // kendo angular have this UX
                this.switchDateSegment(-1);
            }
        }
        this.tryTriggerValueChange({
            oldValue: oldExistingDateValue,
            event: e
        });
        this.triggerInputEnd({ event: e, error: error });
        if (hasCaret) {
            // a format like "F" can dynamically change the resolved format pattern based on the value, e.g.
            // "Tuesday, February 1, 2022 3:04:05 AM" becomes
            // "Wednesday, February 2, 2022 3:04:05 AM" giving a diff of 2 ("Tuesday".length - "Wednesday".length)
            this.setTextAndFormat();
        }
    }
    /**
     * @hidden
     */
    onElementFocus(e) {
        if (this.triggerFocus({ event: e })) {
            return;
        }
        this.isActive = true;
        this.interactionMode = DateInputInteractionMode.None;
        this.switchedPartOnPreviousKeyAction = false;
        this.refreshElementValue();
        if (!this.mouseDownStarted) {
            this.caret(0, this.elementValue.length);
        }
        this.mouseDownStarted = false;
        this.triggerFocusEnd({ event: e });
    }
    /**
     * @hidden
     */
    onElementBlur(e) {
        this.resetSegmentValue = true;
        this.isActive = false;
        if (this.triggerBlur({ event: e })) {
            return;
        }
        if (this.options.autoFill) {
            this.autoFill();
        }
        this.interactionMode = DateInputInteractionMode.None;
        this.switchedPartOnPreviousKeyAction = false;
        this.refreshElementValue();
        this.triggerBlurEnd({ event: e });
    }
    /**
     * @hidden
     */
    onElementChange(e) {
        this.triggerChange({ event: e });
    }
    /**
     * @hidden
     */
    onElementKeyDown(e) {
        if (this.triggerKeyDown({ event: e })) {
            return;
        }
        const { start, end } = this.selection;
        this.keyDownEvent = e;
        this.previousElementValue = this.element.value;
        this.previousElementSelection = { start, end };
        if (this.keyEventMatchesAutoSwitchKeys(e)) {
            const isTabKey = e.keyCode === KeyCode.TAB;
            if (isTabKey) {
                const { start: selectionStart, end: selectionEnd } = this.selection;
                if (e.shiftKey && isTabKey) {
                    this.switchDateSegment(-1);
                }
                else {
                    this.switchDateSegment(1);
                }
                if (selectionStart !== this.selection.start || selectionEnd !== this.selection.end) {
                    // when the selection changes, prevent the default Tab behavior
                    e.preventDefault();
                    return;
                }
            }
            else {
                // do not allow the "input" event to be triggered
                e.preventDefault();
                this.switchDateSegment(1);
                return;
            }
        }
        const symbol = this.currentFormat[this.selection.start];
        const step = this.getStepFromSymbol(symbol);
        let shouldPreventDefault = false;
        if (e.altKey || e.ctrlKey || e.metaKey || e.keyCode === KeyCode.TAB) {
            return;
        }
        switch (e.keyCode) {
            case KeyCode.ARROW_LEFT:
                this.switchDateSegment(-1);
                shouldPreventDefault = true;
                this.switchedPartOnPreviousKeyAction = false;
                break;
            case KeyCode.ARROW_UP:
                this.modifyDateSegmentValue(step, symbol, event);
                shouldPreventDefault = true;
                this.switchedPartOnPreviousKeyAction = false;
                break;
            case KeyCode.ARROW_RIGHT:
                this.switchDateSegment(1);
                shouldPreventDefault = true;
                this.switchedPartOnPreviousKeyAction = false;
                break;
            case KeyCode.ARROW_DOWN:
                this.modifyDateSegmentValue(-step, symbol, event);
                shouldPreventDefault = true;
                this.switchedPartOnPreviousKeyAction = false;
                break;
            case KeyCode.ENTER:
                // todo: handle "change" event
                break;
            case KeyCode.HOME:
                this.selectNearestSegment(0);
                shouldPreventDefault = true;
                this.switchedPartOnPreviousKeyAction = false;
                this.resetSegmentValue = true;
                break;
            case KeyCode.END:
                this.selectNearestSegment(this.elementValue.length);
                shouldPreventDefault = true;
                this.switchedPartOnPreviousKeyAction = false;
                this.resetSegmentValue = true;
                break;
            default:
                // allow the "input" event to handle the change
                return;
        }
        if (shouldPreventDefault) {
            e.preventDefault();
        }
    }
    /**
     * @hidden
     */
    onElementPaste() {
        this.isPasteInProgress = true;
    }
    /**
     * @hidden
     */
    onElementMouseWheel(e) {
        if (!this.options.enableMouseWheel || this.triggerMouseWheel({ event: e })) {
            return;
        }
        if (!this.isActive) {
            return;
        }
        const event = e;
        if (event.shiftKey) {
            this.switchDateSegment((event.wheelDelta || -event.detail) > 0 ? -1 : 1);
        }
        else {
            this.modifyDateSegmentValue((event.wheelDelta || -event.detail) > 0 ? 1 : -1);
        }
        event.returnValue = false;
        if (event.preventDefault) {
            event.preventDefault();
        }
        if (event.stopPropagation) {
            event.stopPropagation();
        }
    }
    updateOnPaste(e) {
        let value = this.intl.parseDate(this.elementValue, this.inputFormat) || this.value;
        if (isPresent(value) && this.dateObject.shouldNormalizeCentury()) {
            value = this.dateObject.normalizeCentury(value);
        }
        const oldDateObjectValue = this.dateObject && this.dateObject.getValue();
        this.writeValue(value);
        this.tryTriggerValueChange({
            oldValue: oldDateObjectValue,
            event: e
        });
    }
    get elementValue() {
        return (this.element || {}).value || '';
    }
    get inputFormat() {
        if (!this.options.format) {
            return Constants.defaultDateFormat;
        }
        if (typeof this.options.format === 'string') {
            return this.options.format;
        }
        else {
            return this.options.format.inputFormat;
        }
    }
    get displayFormat() {
        if (!this.options.format) {
            return Constants.defaultDateFormat;
        }
        if (typeof this.options.format === 'string') {
            return this.options.format;
        }
        else {
            return this.options.format.displayFormat;
        }
    }
    get selection() {
        let returnValue = { start: 0, end: 0 };
        if (this.element !== null && this.element.selectionStart !== undefined) {
            returnValue = {
                start: this.element.selectionStart,
                end: this.element.selectionEnd
            };
        }
        return returnValue;
    }
    setSelection(selection) {
        if (this.element && document.activeElement === this.element) {
            this.element.setSelectionRange(selection.start, selection.end);
            if (selection.start !== selection.end) {
                this.interactionMode = DateInputInteractionMode.Selection;
            }
        }
    }
    /**
     * @hidden
     */
    selectionBySymbol(symbol) {
        let start = -1;
        let end = 0;
        for (let i = 0; i < this.currentFormat.length; i++) {
            if (this.currentFormat[i] === symbol) {
                end = i + 1;
                if (start === -1) {
                    start = i;
                }
            }
        }
        if (start < 0) {
            start = 0;
        }
        if (!this.options.autoCorrectParts && this.currentFormat.length !== this.currentText.length) {
            if (this.currentFormat.length < this.currentText.length) {
                end += this.currentText.length - this.currentFormat.length;
            }
            else {
                end = Math.max(0, end - (this.currentFormat.length - this.currentText.length));
            }
        }
        return { start, end };
    }
    /**
     * @hidden
     */
    selectionByIndex(index) {
        let selection = { start: index, end: index };
        for (let i = index, j = index - 1; i < this.currentFormat.length || j >= 0; i++, j--) {
            if (i < this.currentFormat.length && this.currentFormat[i] !== Constants.formatSeparator) {
                selection = this.selectionBySymbol(this.currentFormat[i]);
                break;
            }
            if (j >= 0 && this.currentFormat[j] !== Constants.formatSeparator) {
                selection = this.selectionBySymbol(this.currentFormat[j]);
                break;
            }
        }
        return selection;
    }
    switchDateSegment(offset) {
        const selection = this.selection;
        if (this.isInCaretMode()) {
            let start = selection.start;
            const currentSymbol = this.currentFormat[start - 1];
            let symbol = "";
            let symbolCandidate = "";
            if (offset < 0) {
                for (let i = start + offset; i >= 0; i--) {
                    symbolCandidate = this.currentFormat[i];
                    if (symbolCandidate !== Constants.formatSeparator &&
                        symbolCandidate !== currentSymbol) {
                        start = i;
                        symbol = symbolCandidate;
                        break;
                    }
                }
            }
            else {
                for (let i = start + offset; i < this.currentFormat.length; i++) {
                    symbolCandidate = this.currentFormat[i];
                    if (symbolCandidate !== Constants.formatSeparator &&
                        symbolCandidate !== currentSymbol) {
                        start = i;
                        symbol = symbolCandidate;
                        break;
                    }
                }
            }
            if (symbol) {
                this.forceUpdate();
                this.setSelection(this.selectionBySymbol(symbol));
                this.interactionMode = DateInputInteractionMode.Selection;
                return;
            }
        }
        this.interactionMode = DateInputInteractionMode.None;
        let { start: selectionStart, end: selectionEnd } = this.selection;
        if (selectionStart < selectionEnd &&
            this.currentFormat[selectionStart] !== this.currentFormat[selectionEnd - 1]) {
            this.setSelection(this.selectionByIndex(offset > 0 ? selectionStart : selectionEnd - 1));
            this.resetSegmentValue = true;
            this.interactionMode = DateInputInteractionMode.None;
            return;
        }
        const previousFormatSymbol = this.currentFormat[selectionStart];
        let a = selectionStart + offset;
        while (a > 0 && a < this.currentFormat.length) {
            if (this.currentFormat[a] !== previousFormatSymbol &&
                this.currentFormat[a] !== Constants.formatSeparator) {
                break;
            }
            a += offset;
        }
        if (this.currentFormat[a] === Constants.formatSeparator) {
            // no known symbol is found
            return;
        }
        let b = a;
        while (b >= 0 && b < this.currentFormat.length) {
            if (this.currentFormat[b] !== this.currentFormat[a]) {
                break;
            }
            b += offset;
        }
        if (a > b && (b + 1 !== selectionStart || a + 1 !== selectionEnd)) {
            this.setSelection({ start: b + 1, end: a + 1 });
            this.resetSegmentValue = true;
        }
        else if (a < b && (a !== selectionStart || b !== selectionEnd)) {
            this.setSelection({ start: a, end: b });
            this.resetSegmentValue = true;
        }
        this.interactionMode = DateInputInteractionMode.None;
    }
    modifyDateSegmentValue(offset, symbol = "", event = {}) {
        if (!this.dateObject || this.options.readonly) {
            return;
        }
        const oldValue = this.value;
        let step = DEFAULT_SEGMENT_STEP;
        const caret = this.caret();
        symbol = symbol || this.currentFormat[caret[0]];
        if (symbol === "S" && (!this.options.steps.millisecond || this.options.steps.millisecond === DEFAULT_SEGMENT_STEP)) {
            const msDigits = millisecondDigitsInFormat(this.inputFormat);
            step = millisecondStepFor(msDigits);
        }
        this.dateObject.modifyPart(symbol, step * offset);
        this.tryTriggerValueChange({
            oldValue: oldValue,
            event: event
        });
        this.forceUpdate();
        this.setSelection(this.selectionBySymbol(symbol));
    }
    /**
     * @hidden
     */
    tryTriggerValueChange(args = { oldValue: null, event: {} }) {
        if (!isEqual(this.value, args.oldValue)) {
            return this.triggerValueChange(args);
        }
    }
    /**
     * @hidden
     */
    triggerValueChange(args = { oldValue: null, event: {} }) {
        return this.trigger(VALUE_CHANGE, extend(args, {
            value: this.value
        }));
    }
    /**
     * @hidden
     */
    triggerInput(args = { event: {} }) {
        return this.trigger(INPUT, extend(args, {
            value: this.value
        }));
    }
    /**
     * @hidden
     */
    triggerInputEnd(args = { event: {}, error: null }) {
        return this.trigger(INPUT_END, extend(args, {
            value: this.value
        }));
    }
    /**
     * @hidden
     */
    triggerFocus(args = { event: {} }) {
        return this.trigger(FOCUS, extend({}, args));
    }
    /**
     * @hidden
     */
    triggerFocusEnd(args = { event: {} }) {
        return this.trigger(FOCUS_END, extend({}, args));
    }
    /**
     * @hidden
     */
    triggerBlur(args = { event: {} }) {
        return this.trigger(BLUR, extend({}, args));
    }
    /**
     * @hidden
     */
    triggerBlurEnd(args = { event: {} }) {
        return this.trigger(BLUR_END, extend({}, args));
    }
    /**
     * @hidden
     */
    triggerChange(args = { event: {} }) {
        return this.trigger(CHANGE, extend(args, {
            value: this.value
        }));
    }
    /**
     * @hidden
     */
    triggerKeyDown(args = { event: {} }) {
        return this.trigger(KEY_DOWN, extend({}, args));
    }
    /**
     * @hidden
     */
    triggerMouseWheel(args = { event: {} }) {
        return this.trigger(MOUSE_WHEEL, extend({}, args));
    }
    /**
     * @hidden
     */
    forceUpdate() {
        this.setTextAndFormat();
        this.refreshElementValue();
    }
    /**
     * @hidden
     */
    forceUpdateWithSelection() {
        const { start, end } = this.selection;
        const elementValueLength = this.elementValue.length;
        this.forceUpdate();
        const selectionOffset = this.elementValue.length - elementValueLength;
        this.setSelection({
            start: start + selectionOffset,
            end: end + selectionOffset
        });
    }
    /**
     * @hidden
     */
    setTextAndFormat() {
        const { text: currentText, format: currentFormat } = this.dateObject.getTextAndFormat();
        this.currentFormat = currentFormat;
        this.currentText = currentText;
    }
    /**
     * @hidden
     */
    setElementValue(value) {
        this.element.value = value;
    }
    /**
     * @hidden
     */
    getStepFromSymbol(symbol) {
        /* eslint-disable no-fallthrough */
        switch (symbol) {
            case "S":
                return Number(this.options.steps.millisecond);
            case "s":
                return Number(this.options.steps.second);
            case "m":
                return Number(this.options.steps.minute);
            // represents hour as value from 01 through 12
            case "h":
            // represents hour as value from 01 through 23
            case "H":
                return Number(this.options.steps.hour);
            case "M":
                return Number(this.options.steps.month);
            // there is no 'D' format specifier for day
            case "d":
            // used for formats such as "EEEE, MMMM d, yyyy",
            // where "EEEE" stands for full name of the day e.g. Monday
            case "E":
                return Number(this.options.steps.day);
            // there is no 'Y' format specifier for year
            case "y":
                return Number(this.options.steps.year);
            default:
                return DEFAULT_SEGMENT_STEP;
        }
        /* eslint-enable no-fallthrough */
    }
    /**
     * @hidden
     */
    restorePreviousInputEventState() {
        this.restorePreviousElementValue();
        this.restorePreviousElementSelection();
    }
    /**
     * @hidden
     */
    restorePreviousElementValue() {
        this.setElementValue(this.previousElementValue || '');
    }
    /**
     * @hidden
     */
    restorePreviousElementSelection() {
        const { start, end } = this.previousElementSelection;
        this.setSelection({ start: start || 0, end: end || 0 });
    }
    writeValue(value) {
        this.verifyValue(value);
        this.dateObject = this.getDateObject(value);
        this.refreshElementValue();
    }
    verifyValue(value) {
        if (value && !isValidDate(value)) {
            throw new Error("The 'value' should be a valid JavaScript Date instance.");
        }
    }
    refreshElementValue() {
        const element = this.element;
        const format = this.isActive ? this.inputFormat : this.displayFormat;
        const { text: currentText, format: currentFormat } = this.dateObject.getTextAndFormat(format);
        this.currentFormat = currentFormat;
        this.currentText = currentText;
        const hasPlaceholder = this.options.hasPlaceholder || isPresent(this.options.placeholder);
        const showPlaceholder = !this.isActive &&
            hasPlaceholder &&
            !this.dateObject.hasValue();
        if (hasPlaceholder && isPresent(this.options.placeholder)) {
            element.placeholder = this.options.placeholder;
        }
        const newElementValue = showPlaceholder ? "" : currentText;
        this.previousElementValue = this.elementValue;
        this.setElementValue(newElementValue);
    }
    /**
     * @hidden
     */
    caret(start, end = start) {
        const isPosition = start !== undefined;
        let returnValue = [start, start];
        const element = this.element;
        if (isPosition && (this.options.disabled || this.options.readonly)) {
            return undefined;
        }
        try {
            if (element.selectionStart !== undefined) {
                if (isPosition) {
                    if (isDocumentAvailable() && document.activeElement !== element) {
                        element.focus();
                    }
                    element.setSelectionRange(start, end);
                }
                returnValue = [element.selectionStart, element.selectionEnd];
            }
        }
        catch (e) {
            returnValue = [];
        }
        return returnValue;
    }
    selectNearestSegment(index) {
        // Finds the nearest (in both directions) known part.
        for (let i = index, j = index - 1; i < this.currentFormat.length || j >= 0; i++, j--) {
            if (i < this.currentFormat.length && this.currentFormat[i] !== "_") {
                this.selectDateSegment(this.currentFormat[i]);
                return;
            }
            if (j >= 0 && this.currentFormat[j] !== "_") {
                this.selectDateSegment(this.currentFormat[j]);
                return;
            }
        }
    }
    selectDateSegment(symbol) {
        let begin = -1;
        let end = 0;
        for (let i = 0; i < this.currentFormat.length; i++) {
            if (this.currentFormat[i] === symbol) {
                end = i + 1;
                if (begin === -1) {
                    begin = i;
                }
            }
        }
        if (begin < 0) {
            begin = 0;
        }
        this.caret(0, 0);
        this.caret(begin, end);
    }
    /**
     * @hidden
     */
    getDateObject(value) {
        const { leadingZero } = (this.dateObject || {}) || null;
        this.options.value = value;
        const dateObject = this.createDateObject();
        dateObject.setLeadingZero(this.isActive ? leadingZero : null);
        return dateObject;
    }
    /* tslint:disable:align */
    /**
     * @hidden
     */
    createDateObject() {
        const defaultOptions = this.getDateObjectOptions();
        const dateObject = new DateObject(extend({}, defaultOptions));
        return dateObject;
    }
    /**
     * @hidden
     */
    getDateObjectOptions() {
        const newOptions = {
            intlService: this.options.intlService,
            formatPlaceholder: this.options.formatPlaceholder ? this.options.formatPlaceholder : 'formatPattern',
            format: this.inputFormat,
            cycleTime: this.options.cycleTime,
            twoDigitYearMax: this.options.twoDigitYearMax,
            autoCorrectParts: this.options.autoCorrectParts,
            value: this.options.value
        };
        return newOptions;
    }
    /* tslint:enable:align */
    /**
     * @hidden
     */
    keyEventMatchesAutoSwitchKeys(keyObject) {
        const autoSwitchKeys = (this.options.autoSwitchKeys || [])
            .map(x => x.toString().toLowerCase().trim());
        if (autoSwitchKeys.indexOf(keyObject.keyCode.toString()) >= 0 ||
            autoSwitchKeys.indexOf(keyObject.keyCode) >= 0 ||
            autoSwitchKeys.indexOf(keyObject.key.toLowerCase().trim()) >= 0) {
            return true;
        }
        return false;
    }
    /**
     * @hidden
     */
    autoFill() {
        let dateObject = this.dateObject, currentDate = new Date(), day, month, year, hours, minutes, seconds;
        if (dateObject.date || dateObject.month || dateObject.year || dateObject.hours || dateObject.minutes || dateObject.seconds) {
            year = dateObject.year ? dateObject.value.getFullYear() : currentDate.getFullYear(),
                month = dateObject.month ? dateObject.value.getMonth() : currentDate.getMonth(),
                day = dateObject.date ? dateObject.value.getDate() : currentDate.getDate(),
                hours = dateObject.hours ? dateObject.value.getHours() : currentDate.getHours(),
                minutes = dateObject.minutes ? dateObject.value.getMinutes() : currentDate.getMinutes(),
                seconds = dateObject.seconds ? dateObject.value.getSeconds() : currentDate.getSeconds();
            dateObject.setValue(new Date(year, month, day, hours, minutes, seconds));
            this.refreshElementValue();
            this.triggerValueChange();
        }
    }
}
