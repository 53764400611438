import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';
import { ConfigService } from './config.service';
import { map, catchError, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppNotificationService } from './app-notification.service';
import { SetRankService } from './set-rank.service';

@Injectable({
  providedIn: 'root',
})
export class QrCategoriesService implements SetRankService {
  public entity = CONSTANTS.entity.qrCategory;
  public errorDescription = 'Cannot save Navigation Code Category';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private translateService: TranslateService,
    private appNotificationService: AppNotificationService,
  ) {
    this.translateService.get('general.unexpectedError').subscribe((res: string) => {
      this.errorDescription = res;
    });
  }

  getById(id: string): Observable<{
    success: boolean;
    loading: boolean;
    error: any;
    data: { id: string; icon: string; name: string; qrCodeIds: string[]; imageId: string; nameLocalization: any[] };
  }> {
    return this.getAction('/qrCategory/' + id).pipe(
      map((r) => ({
        data: r,
        error: null,
        success: true,
        loading: false,
      })),
      catchError((e) =>
        e.status == 404
          ? of({
              data: null,
              error: null,
              success: true,
              loading: false,
            })
          : of({
              data: null,
              error: e,
              success: false,
              loading: false,
            }),
      ),
      startWith({
        data: null,
        error: null,
        success: true,
        loading: true,
      }),
    );
  }

  edit(id: any, payload: any): Observable<any> {
    return this.putAction(this.entity + '/' + id, payload);
  }

  add(payload: any): Observable<any> {
    return this.postAction(this.entity, payload);
  }

  delete(ids: string[]) {
    return this.postAction(this.entity + '/delete', ids);
  }

  getRank(): Observable<any> {
    return this.getAction(this.entity + '/GetRank');
  }

  setRank(payload: any): Observable<any> {
    return this.postAction(this.entity + '/SetRank', payload);
  }

  cueIcons(): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + '/cueIcon';
    return this.http.get(url).pipe(
      map((r) => ({ data: r, success: true })),
      catchError((errorData) =>
        of({
          success: false,
          errorCode: errorData.error.status,
          errorDescription: errorData.error.title,
        }),
      ),
    );
  }

  private putAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.put<any>(url, payload).pipe(
      map((x) => ({ success: true })),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private getAction(urlPostfix: string): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.get<any>(url).pipe(
      map((x) => x),
      catchError((x) => {
        return of({ success: false, errorCode: x.status, errorDescription: this.errorDescription });
      }),
    );
  }

  private postAction(urlPostfix: string, payload: any): Observable<any> {
    const url = `${this.configService.value.apiURL}` + CONSTANTS.api.prefix + urlPostfix;
    return this.http.post<any>(url, payload).pipe(
      map((x) => {
        if (!x.success) {
          this.appNotificationService.error(x.errorCode + ' ' + x.errorDescription);
        }
        return x;
      }),
      catchError((x) => {
        this.appNotificationService.error(x.status + ' ' + 'Unexpected error.');
        return of({
          success: false,
          errorCode: x.status,
          errorDescription: 'Unexpected error.',
        });
      }),
    );
  }
}
